$(function(){
    var menu = $('#mobileMenu');
    var body = $('body');
    
    $('.hamburger-menu').click(function(){ 
        if(!$(this).hasClass('active')){
            $(this).addClass('active');
            body.addClass('mobileMenuOpen');
        }else{
            $(this).removeClass('active');
            body.removeClass('mobileMenuOpen');
        }
    });
    
    $('.close', menu).click(function(){
        body.removeClass('mobileMenuOpen');
        $('.hamburger-menu').removeClass('active');
    });
    
    $('.toggleDropdown').click(function(){
        var relevantHeader = $(this).closest('header');
        !relevantHeader.hasClass('active') ? relevantHeader.addClass('active') : relevantHeader.removeClass('active');
    });
});




